import { createEnv } from '@t3-oss/env-nextjs';
import { z } from 'zod';

export const env = createEnv({
  server: {
    CLERK_SECRET_KEY: z.string(),
    DEV_IP: z.string().optional(),
    IMAGEKIT_SECRET: z.string(),
    INTEGRATION_POST_HOG_API_KEY: z.string(),
    POSITIONSTACK_API_KEY: z.string(),
    STRIPE_SECRET_LIVE: z.string(),
    STRIPE_SECRET_TEST: z.string(),
  },
  client: {
    NEXT_PUBLIC_APPLICATION_ENVIRONMENT: z.enum(['development', 'testing', 'staging', 'production']).optional(),
    NEXT_PUBLIC_COHERENCE_ENVIRONMENT_DOMAIN: z.string().optional(),
    NEXT_PUBLIC_CRISP_WEBSITE_ID: z.string().optional(),
    NEXT_PUBLIC_EF_SERVICE_URLS: z.preprocess(
      (obj) => JSON.parse((obj ?? '{}') as string),
      z.object({
        gateway: z.string(),
        organizations: z.string(),
      })
    ),
    NEXT_PUBLIC_EF_API_URL: z.string(),
    NEXT_PUBLIC_FATHOM_SITE_ID: z.string().optional(),
    NEXT_PUBLIC_IMAGEKIT_PUBLIC: z.string(),
    NEXT_PUBLIC_IMAGEKIT_URL: z.string(),
    NEXT_PUBLIC_SENTRY_DEBUG_CLIENT: z.boolean().optional(),
    NEXT_PUBLIC_SENTRY_DEBUG_EDGE: z.boolean().optional(),
    NEXT_PUBLIC_SENTRY_DEBUG_SERVER: z.boolean().optional(),
    NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
    NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS_OBJECT: z
      .preprocess(
        (obj) => JSON.parse(obj as string),
        z.object({ AU: z.string(), CA: z.string(), DE: z.string(), GB: z.string(), US: z.string() })
      )
      .optional(),
    NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS: z.preprocess((obj) => JSON.parse((obj as string) ?? '[]'), z.array(z.string())),
    NEXT_PUBLIC_STRIPE_PUBLIC_LIVE: z.string(),
    NEXT_PUBLIC_COHERENCE_BUILD_SHA: z.string().optional(),
  },
  runtimeEnv: {
    CLERK_SECRET_KEY: process.env.CLERK_SECRET_KEY,
    DEV_IP: process.env.DEV_IP,
    IMAGEKIT_SECRET: process.env.IMAGEKIT_SECRET,
    INTEGRATION_POST_HOG_API_KEY: process.env.INTEGRATION_POST_HOG_API_KEY,
    NEXT_PUBLIC_APPLICATION_ENVIRONMENT: process.env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT,
    NEXT_PUBLIC_COHERENCE_BUILD_SHA: process.env.COHERENCE_BUILD_SHA,
    NEXT_PUBLIC_COHERENCE_ENVIRONMENT_DOMAIN: process.env.NEXT_PUBLIC_COHERENCE_ENVIRONMENT_DOMAIN,
    NEXT_PUBLIC_CRISP_WEBSITE_ID: process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
    NEXT_PUBLIC_EF_SERVICE_URLS: process.env.NEXT_PUBLIC_EF_SERVICE_URLS,
    NEXT_PUBLIC_EF_API_URL: process.env.NEXT_PUBLIC_EF_API_URL,
    NEXT_PUBLIC_FATHOM_SITE_ID: process.env.NEXT_PUBLIC_FATHOM_SITE_ID,
    NEXT_PUBLIC_IMAGEKIT_PUBLIC: process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC,
    NEXT_PUBLIC_IMAGEKIT_URL: process.env.NEXT_PUBLIC_IMAGEKIT_URL,
    NEXT_PUBLIC_SENTRY_DEBUG_CLIENT: process.env.NEXT_PUBLIC_SENTRY_DEBUG_CLIENT,
    NEXT_PUBLIC_SENTRY_DEBUG_SERVER: process.env.NEXT_PUBLIC_SENTRY_DEBUG_SERVER,
    NEXT_PUBLIC_SENTRY_DEBUG_EDGE: process.env.NEXT_PUBLIC_SENTRY_DEBUG_EDGE,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS_OBJECT: process.env.NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS_OBJECT,
    NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS: process.env.NEXT_PUBLIC_STRIPE_ACCOUNT_TESTS,
    NEXT_PUBLIC_STRIPE_PUBLIC_LIVE: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_LIVE,
    POSITIONSTACK_API_KEY: process.env.POSITIONSTACK_API_KEY,
    STRIPE_SECRET_LIVE: process.env.STRIPE_SECRET_LIVE,
    STRIPE_SECRET_TEST: process.env.STRIPE_SECRET_TEST,
  },
});

type Services = {
  gateway: string;
  organizations: string;
};

function getServiceUrl(service: string): string {
  if (process.env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT === 'development') {
    const parseData = JSON.parse(process.env.NEXT_PUBLIC_EF_SERVICE_URLS ?? '{}');
    return parseData[service];
  }

  return `https://${env.NEXT_PUBLIC_COHERENCE_ENVIRONMENT_DOMAIN}`;
}

export function getServiceUrls() {
  return {
    gateway: getServiceUrl('gateway'),
    organizations: getServiceUrl('organizations'),
  };
}

function getConfig() {
  const services: Services = getServiceUrls();

  return {
    crispWebsiteId: env.NEXT_PUBLIC_CRISP_WEBSITE_ID,
    services,
  };
}

export const config = getConfig();
